import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getProfileInfo, sendCustomerEvent } from "../services/ida";
import {
  convertStrToParam,
  isDesktopView,
  isFieldValid,
} from "../services/utils";
import Button from "./Button";
import QRC from "./QRCode";

const bankIdLogo = new URL(
  "../assets/images/bankid-white.svg",
  import.meta.url
);

const apiUrl = process.env.IDA_API_URL;

export default function BankIdLogin({ backUrl = "/signin" }) {
  const [status, setStatus] = useState("init");
  const [loading, setLoading] = useState(true);
  const [qrHash, setQrHash] = useState("");
  const [autoStartToken, setAutoStartToken] = useState();
  const qrInterval = useRef<NodeJS.Timer | null>(null);
  const collectInterval = useRef<NodeJS.Timer | null>(null);
  const [orderRef, setOrderRef] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    void startLogin();

    return () => {
      clearFetchQrHash();
      setStatus("init");
    };
  }, []);

  useEffect(() => {
    if (!orderRef) {
      return;
    }

    qrInterval.current = setInterval(() => {
      void fetchQrHash();
    }, 2000);
    collectInterval.current = setInterval(() => {
      void fetchCollect();
    }, 2000);

    return () => {
      if (qrInterval.current) {
        clearInterval(qrInterval.current);
      }
      if (collectInterval.current) {
        clearInterval(collectInterval.current);
      }
    };
  }, [orderRef]);

  const startLogin = async () => {
    const paramString = "";

    setStatus("pending");

    const res = await fetch(`${apiUrl}/bankid/start${paramString}`, {
      method: "GET",
    }).catch((error) => {
      console.error(error);

      clearFetchQrHash();
      setStatus("init");
      setLoading(false);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();
    if (!data.orderRef) {
      void startLogin();
      return;
    }
    setQrHash(data.qrHash);
    setOrderRef(data.orderRef);
    setAutoStartToken(data.autoStartToken);
    setLoading(false);
  };

  const fetchQrHash = async () => {
    const params = new URLSearchParams({
      orderRef: orderRef,
    });
    const res = await fetch(`${apiUrl}/bankid/refreshQR?${params.toString()}`, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    }).catch((error) => {
      console.error(error);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();
    setQrHash(data.qrData);
  };

  const fetchCollect = async () => {
    const params = new URLSearchParams({
      orderRef: orderRef,
      source: "CustomerWebApp"
    });
    const res = await fetch(`${apiUrl}/bankid/collect?${params.toString()}`, {
      method: "GET",
    }).catch((error) => {
      console.error(error);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();

    const hintCode = data.hintCode;
    const collectStatus = data.status;
    const accessToken = data.accessToken;
    const refreshToken = data.refreshToken;
    const expiresIn = data.expiresIn;

    if (accessToken) {
      clearFetchQrHash();
      const profilePromise = getProfileInfo(accessToken)
        .then(async (data) => {
          if (data) {
            void sendCustomerEvent(
                accessToken,
                "LoggedIn",
                ""
            );
            const payload = {
              profile: data,
              firstName: data.firstName,
              lastName: data.lastName,
              personNumber: data.personNumber,
              accessToken: accessToken,
              refreshToken: refreshToken,
              customerIdaAccountId: data.id,
              expDate: Date.now() + parseInt(expiresIn) * 1000,
            };
            const res = await auth.signInBankId(payload);
            if (res?.error) {
              console.log(
                "Error:",
                JSON.stringify(res.error),
                JSON.stringify(res)
              );
              const p = convertStrToParam(res.error);
              auth.addNotification(t([`messages.${p}`, "messages.general"]));
            } else {
              // const from = location.state?.from?.pathname || "/";
              const from = "/todo";
              navigate(from, { replace: true });
            }
          }
        })
        .catch((e) => console.log(e));

      void Promise.allSettled([profilePromise]).then(() => {
        setStatus("complete");
        // navigate("/vault");
      });

      return;
    }

    switch (hintCode) {
      case "userCancel":
        setStatus("canceled");
        clearFetchQrHash();
        break;
      case "userSign":
        setStatus("signing");
        if (qrInterval.current) {
          clearInterval(qrInterval.current);
        }
        setQrHash("");
        break;
      case "startFailed":
        setStatus("failure");
        clearFetchQrHash();
        break;
      case "outstandingTransaction":
      default:
        break;
    }
  };

  const clearFetchQrHash = () => {
    if (qrInterval.current) {
      clearInterval(qrInterval.current);
    }
    if (collectInterval.current) {
      clearInterval(collectInterval.current);
    }
    setQrHash("");
  };

  const renderCancelButton = () => {
    return (
      <Button
        onClick={() => {
          setLoading(true);
          // clearFetchQrHash();
          // setStatus("init");
          window.location.href = backUrl;
        }}
        title="Stäng"
        size="small"
        cssClasses="mt-4 mx-auto"
        rightArrow={false}
      />
    );
  };

  const renderStartButton = () => {
    return (
      <Button
        onClick={() => {
          setLoading(true);
          void startLogin();
        }}
        title="LOGGA IN MED MOBILT BANKID"
        cssClasses="mx-auto mt-5 lg:mt-10"
        icon={{ url: bankIdLogo, width: 30 }}
      />
    );
  };

  const renderOpenAppButton = (autoStartToken: string) => {
    if (isDesktopView()) {
      return (
        <a
          className="underline"
          href={`bankid:///?autostarttoken=${autoStartToken}&redirect=null`}
        >
          Använd BankID på denna enhet
        </a>
      );
    } else {
      return (
        <Button
          onClick={() => {
            window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
          }}
          title="Öppna BankID-appen"
          cssClasses="mx-auto mt-5 lg:mt-14"
          icon={{ url: bankIdLogo, width: 30 }}
        />
      );
    }
  };

  const renderText = () => {
    return (
      <div className="mx-auto mt-10 max-w-[400px] font-interlight text-[14px]">
        Genom att fortsätta accepterar jag{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="underline"
          href="https://fenixbegravning.se/pdf/Fenix-Allmanna-villkor.pdf"
        >
          villkoren
        </a>{" "}
        för Fenix Familys tjänst samt bekräftar att jag har läst Familys{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="underline"
          href="https://fenixbegravning.se/pdf/Fenix-Integritetspolicy.pdf"
        >
          integritetspolicy
        </a>
        .
      </div>
    );
  };

  return (
    <div className="relative">
      {/*{status === "init" && renderStartButton()}*/}
      {status === "pending" && (
        <>
          <div className="mb-5 text-left lg:px-5">
            {isDesktopView() && (
              <ul className="list-outside list-decimal pl-5">
                <li>Öppna BankID-appen i mobilen.</li>
                <li>Tryck på QR-symbolen i BankID-appen.</li>
                <li>Rikta kameran mot QR-koden i denna ruta.</li>
              </ul>
            )}
          </div>
          <div className="mx-auto h-auto w-full lg:max-w-[250px]">
            {isDesktopView() && <QRC value={qrHash} />}
            {process.env.NODE_ENV !== "production" && !isDesktopView() && (
              <QRC value={qrHash} />
            )}

            {autoStartToken && (
              <div className="mt-3">{renderOpenAppButton(autoStartToken)}</div>
            )}
          </div>
          {isDesktopView() && renderCancelButton()}
          {renderText()}
        </>
      )}
      {status === "signing" && (
        <>
          <div className="mx-auto mb-5 max-w-[400px]">
            Skriv in din säkerhetskod i BankID- appen och välj Identifiera eller
            Skriv under.
          </div>
          {renderCancelButton()}
        </>
      )}
      {status === "canceled" && (
        <>
          <div className="mb-5">
            Identifieringen avbröts. <br /> Prova igen!
          </div>
          {renderStartButton()}
        </>
      )}
      {status === "failure" && (
        <>
          <div className="mx-auto mb-5 max-w-[400px]">
            Vi kunde inte hitta något BankID eller så misslyckades scanningen av
            QR-koden. Prova igen!
          </div>
          {renderStartButton()}
        </>
      )}
    </div>
  );
}
